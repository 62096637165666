import { Trans } from '@lingui/macro';
import {
  Box,
  Paper,
  PaperProps,
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import Image, { ImageProps } from 'next/image';
import React from 'react';
import { subFont } from 'src/assets/styles/fonts';
import { AspectRatio } from 'src/components/core/aspect-ratio';
import { MyLink } from 'src/components/core/mine/my-link';
import { useLocale } from 'src/hooks/use-locale';
import { useSyncTimer } from 'src/hooks/use-timer';
import { CRYPTO_DP } from 'src/utils/constants/common-constant';
import { getIconSrc } from 'src/utils/constants/icon-coins';
import { Paths } from 'src/utils/constants/path';
import { BN } from 'src/utils/helpers/big-number';
import { calcTourStatus, getCountTime } from 'src/utils/helpers/tournament';
import { generatePath } from 'src/utils/libs/react-router-dom';
import { TournamentItem as _TournamentItem } from 'src/utils/types/tournament';
import { TournamentBadge } from '../tournament-badge';
import { TournamentTimer } from '../tournament-timer';
export const TournamentItem: React.FCC<
  {
    data?: _TournamentItem;
    readonly?: boolean;
    useNexImage?: boolean;
    nextImageProps?: ImageProps;
    tournamentNameProps?: TypographyProps;
  } & PaperProps
> = ({
  data,
  readonly,
  useNexImage,
  nextImageProps,
  tournamentNameProps,
  ...props
}) => {
  const status = calcTourStatus(data);

  const date = useSyncTimer(getCountTime(data, status), 60 * 1000);
  if (!data) return null;

  const url = generatePath(Paths.TournamentDetails, { id: data._id });

  return (
    <Paper
      {...props}
      {...(!readonly && {
        component: MyLink,
        href: url,
        underline: 'none',
        display: 'block',
      })}
      sx={{ overflow: 'hidden', ...props?.sx }}>
      <AspectRatio ratio={9 / 16}>
        {useNexImage && nextImageProps ? (
          <Image {...nextImageProps} />
        ) : (
          <Box
            component="img"
            sx={{ objectFit: 'cover', zIndex: -100 }}
            width="100%"
            height="100%"
            src={data.banner}
            alt={data.name}
          />
        )}

        <TournamentBadge
          sx={{ position: 'absolute', right: '16px' }}
          status={status}
        />
      </AspectRatio>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography
          align="center"
          className={subFont.className}
          sx={{ fontSize: 20, lineHeight: 1 }}
          {...tournamentNameProps}>
          {data.name}
        </Typography>
        <Prize data={data} />
        <TournamentTimer date={date} status={status} />
      </Stack>
    </Paper>
  );
};

export const Prize: React.FCC<
  { data: _TournamentItem; text?: React.ReactNode } & PaperProps
> = ({
  data,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  text = <Trans id="tournaments.prize_pool">Prize Pool</Trans>,
  ...paperProps
}) => {
  const { FORMAT } = useLocale();
  return (
    <Paper
      {...paperProps}
      component={Stack}
      spacing={0.5}
      variant="outlined"
      sx={{
        borderRadius: 2,
        p: 1.5,
        bgcolor: 'background.default',
        borderColor: 'primary.main',
        ...paperProps?.sx,
      }}>
      <Typography align="center" sx={{ fontSize: 16, fontWeight: 600 }}>
        {text}
      </Typography>
      <Stack
        spacing={0.5}
        direction="row"
        sx={{
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
          {new BN(data.prizePool).dp(CRYPTO_DP).toFormat(FORMAT)}
        </Typography>
        {data.token && (
          <img
            src={getIconSrc(data.token.toLowerCase())}
            alt={data.token}
            width={20}
            height={20}
          />
        )}
      </Stack>
    </Paper>
  );
};
